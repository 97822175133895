<template>
  <div id="app">
     <h4>اسطول الشحن ومؤشرات قياس الاداء</h4>
    <nav class="navbar navbar-expand navbar-dark">
      <router-link to="/" class="navbar-brand"> <img src="./assets/banner.png" /> </router-link>
      <div class="navbar-nav mr-auto">
        <!-- <ul> -->
        <div class="nav-item">
          <router-link to="/cars-transport" class="nav-link">نقل السيارات</router-link>
          <router-link to="/heavy-transport" class="nav-link">نقل المثقلات</router-link>
          <router-link to="/vegetable-oils-transport" class="nav-link">الزيوت النباتية</router-link>
          <router-link to="/sheep" class="nav-link">نقل الاغنام</router-link>
          <router-link to="/crude-oil" class="nav-link">النفط الخام</router-link>
          <router-link to="/mobarad" class="nav-link">المبرد</router-link>

          <router-link to="/containers" class="nav-link">نقل الحاويات</router-link>
          <router-link to="/goods" class="nav-link">البضائع العامة</router-link>

          <router-link to="/permits" class="nav-link">التصاريح</router-link>
          <router-link to="/drivers" class="nav-link">السائقين</router-link>
          <router-link to="/companies" class="nav-link">الشركات</router-link>
          <router-link to="/cars"   class="nav-link">المركبات</router-link>
           <!-- <router-link to="/" class="nav-link">المركبات</router-link> -->

          
        </div>
        <!-- </ul> -->
      </div>
    </nav>
    <div class="container mt-3">
      <router-view :key="$route.name"></router-view>
    </div>
    <div class="container mt-4"></div>
  </div>
</template>


<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
#app {
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #3883cf; */
  margin-top: 20px;
}
.container {
  max-width: 1000px;
}
.navbar {
direction: rtl;
background-color: #5185a3e6;
}
.nav-link {
  float: left;
  list-style-type: none;
  overflow: hidden;

}



.nav-item a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;

}


a.nav-link {
    font-size: 18px;
  font-weight: bold;
}

 a:hover {
  background-color: #9cd1f0 !important;
  /* font-size: 18px !important; */
  /* font-style: italic !important; */
}


div#app > h4 {
    text-align: center;
    font-weight: bold;
}
.navbar-dark, .navbar-nav, .nav-link{
  color: #fff !important ;
}
.router-link-active{
  color: rgb(199, 227, 245) !important;
  text-decoration: underline !important;
}
</style>

<template>
  <v-container>
    
    <div v-if="loading == false">
      <div v-if="dat">
        <div class="buttons">
     <button
          class="button"
          style="vertical-align: middle"
          v-on:click="createPDF()"
        >
          <span>PDF</span>
        </button>
        <button
          class="button"
          style="vertical-align: middle"
          v-on:click="createImg()"
        >
          <span>صورة</span>
        </button>
        </div>
   
        <div id="ref" dir="rtl">
          <vue3-simple-html2pdf
            ref="vue3SimpleHtml2pdf"
            :options="pdfOptions"
            :filename="exportFilename"
          >
            <div
              class="tables"
              v-for="(item, index) in dat"
              :key="item.id"
              :id="index"
            >
             <!-- <router-link to="/" class="logo-pdf"> <img src="../assets/banner.png" /> </router-link> -->
              <h2>{{ index }}</h2>
              <div class="c_flex_center">
                <table class="display" id="datatable">
                <thead>
                  <tr>
                    <th v-for="(header, k) in item['columns']" :key="k.id">
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in item['rows']" :key="row.id">
                    <td v-for="cell in row" :key="cell.id">{{ cell }}</td>
                  </tr>
                </tbody>
                </table>
                <div v-if="item['pie']" class="pie" id="pie">
                  <apexchart
                    type="donut"
                    :width="width" height="350"
                    :options="getPieOptions(item['pie'])"
                    :series="item['pie']['values']"
                  ></apexchart>
                </div>
                <div v-if="item['bar']" class="bar" id="bar">
                  <apexchart
                    type="bar"
                    :width="width" height="350"
                    :options="getBarOptions(item['bar'])"
                    :series="getBarSeries(item['bar'])"
                  ></apexchart>
                </div>
                
           
              </div>
             
                   <div v-if="item['notes']" class="notes" id="notes">
                  <ul v-for="note in item['notes']" :key="note.id">
                    <li class="notes-li">{{ note }}</li>
                  </ul>
                </div>
        
            </div>
            
          </vue3-simple-html2pdf>
        </div>
      </div>
      <div v-else>
        <h2>الرجاء المحاولة مرة اخرى او العودة لاحقاً</h2>
      </div>
    </div>
    <div v-else-if="loading == true">
      <img src="../assets/loading.gif" />
    </div>
    <div v-else>
      <h2>الرجاء المحاولة مرة اخرى او العودة لاحقاً</h2>
    </div>
     <div class="ffoter">هذا التقرير صادر عن المنظومة الإلكترونية لوثيقة النقل البري <span class="created-date"> صادر بتاريخ : {{currentDate()}}</span></div>
  </v-container>
</template>
<script>

import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import domtoimage from "dom-to-image";
import axios from "axios";
import $ from "jquery";
import VueApexCharts from "vue3-apexcharts";
import saveAs from "file-saver";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
  components: { apexchart: VueApexCharts },
  mounted() {
    this.fetchData();
  },
  methods: {
      currentDate() {
      const current = new Date();
      const date = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
      const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      const dateTime = date +' '+ time;
      
      return dateTime;
    },
    fetchData() {
      axios
        .get(
          "https://gltrc.com/api/performance-measurement-indicators-" +
               (this.$route.name
              ? this.$route.name
              : "cars"), 
            // (this.$route.params.type
            //   ? this.$route.params.type
            //   : this.$route.name), 
    //           { withCredentials: true,
    // headers: {
    //   "Accept": "application/json",
    //   "Content-Type": "application/json"
    // }},
    
  //   {
  // auth: {
  //   username: 'dev',
  //   password: 'dev@blueray!'
  // }
  // }
        )
        .then((response) => {
          console.log(response.data);
          this.dat = response.data;
          setTimeout(() => {
          $("table").DataTable({ paging: false, info: false, order: [] });
      }, 50);
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status != 200) {
            this.dat = null;
            this.message = " ";
            this.loading = false;
          }
        });
    },
    getPieOptions(item) {
      return {
        labels: item["labels"],
        series: item["values"],
        chart: {
          // width: "60%",
          height: "100%",
          type: "donut",
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 880,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    getBarOptions(item) {
      return {
        chart: {
          type: "bar",
          height: "100%",
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: item["x"],
        },
        yaxis: {
          title: {
            text: item["y"],
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " " + item["action"];
            },
          },
        },
      };
    },
    getBarSeries(item) {
      var arr = [];
      item["values"].forEach((i) => {
        arr.push({
          name: i["name"],
          data: i["values"],
        });
      });
      return arr;
    },
    createImg() {
      var x = document.getElementsByClassName("dataTables_filter");
      for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }
      domtoimage.toBlob(document.getElementById("ref")).then(function (blob) {
        window.saveAs(blob, "gltrc.png");
      });
      setTimeout(() => {
        for (i = 0; i < x.length; i++) {
          x[i].style.display = "block";
        }
      }, 1000);
    },
    createPDF() {
   
      var x = document.getElementsByClassName("dataTables_filter");
      for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }
      var tables = document.getElementsByClassName("tables");
       var ffooter = document.getElementsByClassName("ffoter");
      var doc = new jsPDF("p", "pt", "a4"); //595.28,  841.89
      // doc.addFont('Roboto-Regular.ttf', 'Roboto-Regular', 'normal');
      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();
      var small = 0;
      var position = 10;
      var divHeight;
      var count = 0;
      var count2 =0;
      var imgData = [];
      var ffoterIm = [];

       const logo = require('/src/assets/banner.png');
   var imgLogo = new Image();
    imgLogo.src = logo;
      const footer = require('/src/assets/footer.png');
   var imgFooter = new Image();
    imgFooter.src = footer;
      tables.forEach(function (item, i) {
        html2canvas(tables[i]).then(function (canvas) {
          imgData[i] = canvas.toDataURL("image/png");
          count++;
        });
    
      });
        ffooter.forEach(function (item, j) {
        html2canvas(ffooter[j]).then(function (canvas) {
           ffoterIm[j] = canvas.toDataURL("image/png");
          count2++;
        });
    
      });
   
   
      var intr = setInterval(() => {
        if (count == tables.length) {
          for (var ii = 0; ii < count; ii++) {
             
           

            divHeight = tables[ii].offsetHeight * 0.75;
            if (
              // tables[ii]. logo-pdf
              ii > 0 &&
              small == ii - 1 &&
              divHeight - 250 + tables[small].offsetHeight * 0.75 < height
            ) {
              position = tables[small].offsetHeight * 0.75 - 50;
            } else if (ii > 0) {
              position = 10;
              small = ii;
               
              doc.addPage();
            }

            var imgProps = doc.getImageProperties(imgData[ii]);
            var pdfHeight = (imgProps.height * width) / imgProps.width;
             doc.addImage(imgLogo, 'JPEG',180, 6, 190, 60);
    
    //  doc.text(250, 800, 'footer_center');
//  doc.text(250,800, 'هذا التقرير صادر عن المنظومة الإلكترونية لوثيقة النقل البري');
//  var b = document.getElementsByClassName("ffoter");


            doc.addImage(imgData[ii], "JPEG", 0, (position+80), width, pdfHeight);
            doc.addImage(ffoterIm[0], "JPEG", 0, 800, 600, 30);
            //  doc.text(10, 825, 'date');
// doc.addImage(imgFooter, 'JPEG',0, 800, 600, 30);
            if (ii === tables.length - 1) {
              doc.save("gltrc_statistics.pdf");
            }
          }

          clearInterval(intr);
        }
      }, 1000);
      setTimeout(() => {
        for (i = 0; i < x.length; i++) {
          x[i].style.display = "block";
        }
      }, 1000);
    },
  },
  data: () => {
    return {
      loading: true,
      dat: [],
      message: "",
      pdfOptions: {
        margin: 50,
        image: {
          type: "jpeg",
          quality: 1,
        },
        html2canvas: {
          scale: 1,
          onclone: (document) => {
            var b = document.getElementsByClassName("bar");
            var p = document.getElementsByClassName("pie");
            var ln;
            if (p.length < b.length) {
              ln = p.length;
            } else {
              ln = b.length;
            }
            for (var i = 0; i < ln; i++) {
              p[i].style.width = "90%";
              b[i].style.width = "90%";
            }
          },
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "p",
        },
      },
      exportFilename: "gltrc_statistics.pdf",
    };
  },
};
</script>
<style>
html{
   height: 100%;
}
body {
  /* margin: 0 auto;
  height: 100%;
  margin: 0;
  font-family: sans-serif;
  font-weight: 100; */
  position: relative;
  
}
/* body::after{
  content: ' ';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
background-image:url("../assets/banner.png");
  background-position: center;  
  background-repeat: space;
  background-attachment: fixed;
  z-index: -1;
  opacity: 0.06;
} */

.display {
  direction: rtl;
  float: left;
  background: linear-gradient(45deg, #49a09d, #847f88);
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

td {
  position: relative;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  color: rgb(0, 0, 0);
}

th {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
tr:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
tr {
  position: relative;
}
tr td:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  bottom: -9999px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}
.pie {
  display: inline-block;
  width: 50%;
}

.bar {
  display: inline-block;
  width: 50%;
}
.button {
  top: 25%;
  right: 10%;
  display: inline-block;
  border-radius: 4px;
  background-color: #5185a3e6;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  padding: 5px;
  width: 80px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}
 
.button:hover span:after {
  opacity: 1;
  right: 0;
}

.notes {
  text-align: right;
    display: inline-block;
    color: #9d9999;
direction: rtl !important;

}
.tables{
margin-bottom: 50px;
    clear: both;
    /* background-color: #fbfbfb; */
    padding: 5px;
    overflow: hidden;
    z-index:3;
    position:relative;
}

.tables::after{

      content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('../assets/watermark.png');
    background-position: center;
    background-repeat: space;
    background-size: 250px;
    background-attachment: fixed;
    z-index: -2;
    opacity: 0.07;
}

div#datatable_wrapper{
/* width: 50% !important; */
    position: relative !important;
    float: right !important;
    /* margin-bottom: 185px !important; */

}
div#pie,
div#bar{
  position: relative !important;
    display: inline-block !important;
    width: 49%  !important;
    /* float: left  !important; */
    /* overflow: hidden !important; */
    /* margin-top: 1% !important; */
}
.tables > h2 {
  text-align: center;
}
.buttons {
    text-align: center;
    margin-bottom: 12 px !important;
}
div#datatable_wrapper thead{
      background-color: #5185a3e6;

}
div#datatable_filter{
  display: none !important;
}
/* .logo-pdf{} */


.c_flex_center{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* margin-bottom: 50px;
    clear: both;
    background-color: #eeeef9;
    padding: 5px;
    overflow: hidden; */
}
.apexcharts-canvas{
  /* height: 300px !important; */
}
.notes-li{
  list-style: none;
}
.watermark {
    opacity: 0.5;
    color: BLACK;
    position: absolute;
    bottom: 0;
    right: 0;
}
.ffoter{
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    background-color: #5185a3e6;
    text-align: right;
    font-size: 17px;
    padding: 10px;
    width: 98%;
    /* margin: 17px; */
    color: white;
    font-weight: 600;
}
span.created-date{
float: left;
}
span.apexcharts-legend-marker{
  margin-left:5px;
}
</style>
<!-- fv2U7tfaThiyQt086fCJAv:APA91bHp1fQ3e-pa-t2ucIZAUGrGBoE99xqmmHp9QS-mQkNUYVFaJa_JyVMN90Q2ul9q-z1gOs_v_eXHZELUceJDlasawZ9nxLZuuMR8UjLeToMzeLWyYkGZnf-AhVms1R5W9CEMxVrr -->
import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
    apiKey: "AIzaSyAUqMWG70sV2eXzWgCO8QiLhHGL0xAkd1s",
    authDomain: "statistics-e089d.firebaseapp.com",
    projectId: "statistics-e089d",
    storageBucket: "statistics-e089d.appspot.com",
    messagingSenderId: "73538403176",
    appId: "1:73538403176:web:1bb86e57024c914f3cb4dd",
    measurementId: "G-H505YGGBM1"
  };
  
  
const app = createApp(App).use(router).use(Vue3SimpleHtml2pdf).use(firebaseConfig).mount('#app')
const analytics = getAnalytics(app);


import { createWebHistory, createRouter } from "vue-router";
import Statistics from "./components/Statistics";
const routes = [
  {
    path: "/",
    // name: "cars",
    component: Statistics,
  },
  // {
  //     path: '/:type',
  //     alias: "/statistics",
  //     name: "statistics",
  //     component: Statistics,
  // },
  {
    path: "/goods",
    name: "goods",
    component: Statistics,
  },
  {
    path: "/mobarad",
    name: "mobarad",
    component: Statistics,
  },
  {
    path: "/cars-transport",
    name: "cars-transport",
    component: Statistics,
  },
  {
    path: "/heavy-transport",
    name: "heavy-transport",
    component: Statistics
  },
  {
    path: "/vegetable-oils-transport",
    name: "vegetable-oils-transport",
    component: Statistics,
  },
  {
    path: "/sheep",
    name: "sheep",
    component: Statistics

  },
  {
    path: "/drivers",
    name: "drivers",
    component: Statistics,
  },
  {
    path: "/companies",
    name: "companies",
    component: Statistics,
  },
  {
    path: "/containers",
    name: "containers",
    component: Statistics,
  },
  {
    path: "/cars",
    name: "cars",
    component: Statistics,
  },
  {
    path: "/permits",
    name: "permits",
    component: Statistics,

  },
  {
    path: "/crude-oil",
    name: "crude-oil",
    component: Statistics,

  }
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
